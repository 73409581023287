import { combineReducers, Reducer } from 'redux';
import { ApplicationState } from '../../types/state/storeTypes';
import { authReducer } from './auth';
import { messagesReducer } from './messages';
import { notificationsReducer } from './notifications';
import { productVariantsPricesReducer } from './productVariantsPrices';
import { wishlistsReducer } from './wishlists';
import { collectionsCacheReducer } from './collectionsCache';
import { ownershipsCacheReducer } from './ownershipsCache';
import { userVerificationReducer } from './userVerification';
import { licenseTypesCacheReducer } from './licenseTypesCache';
import { categoriesCacheReducer } from './categoriesCache';
import { userInterestAnalyticsReducer } from './userInterestsAnalytics';
import { downloadStatusReducer } from './downloadStatus';
import { filtersCacheReducer } from './filtersCache';
import { rateLimitingStatusReducer } from './rateLimitingStatus'
import { buyMoreCreditsReducer } from './buyMoreCredits';
import { creditPacksCacheReducer } from './creditPacksCache';
import { planVariantsCacheReducer } from './planVariantsCache';
import { licenseFlowReducer } from './licenseFlow';
import { subscriptionUpgradeReducer } from './subscriptionUpgrade';
import { eventTrackingReducer } from './eventTracking';
import { cartReducer } from './cart';
import { anonymousUserReducer } from './anonymousUser';

export const reducers: Reducer = combineReducers<any>({
  auth: authReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  productVariantsPrices: productVariantsPricesReducer,
  wishlists: wishlistsReducer,
  collectionsCache: collectionsCacheReducer,
  ownershipsCache: ownershipsCacheReducer,
  userVerification: userVerificationReducer,
  licenseTypes: licenseTypesCacheReducer,
  categoriesCache: categoriesCacheReducer,
  userInterestsAnalytics: userInterestAnalyticsReducer,
  downloadStatus: downloadStatusReducer,
  filtersCache: filtersCacheReducer,
  rateLimitingStatus: rateLimitingStatusReducer,
  buyMoreCredits: buyMoreCreditsReducer,
  creditPacksCache: creditPacksCacheReducer,
  planVariantsCache: planVariantsCacheReducer,
  licenseFlowReducer: licenseFlowReducer,
  subscriptionUpgrade: subscriptionUpgradeReducer,
  eventTracking: eventTrackingReducer,
  cart: cartReducer,
  anonymousUser: anonymousUserReducer,
});
