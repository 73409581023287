import { AnonymousUserState } from '../../types/state/reducers/anonymousUserReducersTypes';
import { LicenseId } from '../../types/api/LicenseTypesTypes';

import { ANONYMOUS_USER_ACTIONS } from '../../constants/actions';

const initialState: AnonymousUserState = {};

export const anonymousUserReducer = (
  state = initialState,
  action: { type: string, licenseId?: LicenseId }
) => {
  const { type, licenseId } = action;
  switch (type) {
    case ANONYMOUS_USER_ACTIONS.SET_LICENSE_ID:
      return { ...state, licenseId };
    default:
      return state;
  }
}
