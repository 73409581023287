import * as fb from './Facebook';
import { CartOrderState, CartProductType } from './../../types/state/reducers/cartReducersTypes';

export const pageview = (url: string) => {
  fb.pageview();
};

export const addToCart = (order: CartOrderState, item?: CartProductType) => {

};

export const initCheckout = (order: CartOrderState) => {

};

export const addPaymentInfo = (order: CartOrderState) => {

};

export const purchased = (completedOrder: any) => {

};
