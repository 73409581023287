export const SHARED = {
  NOOP: 'NOOP'
};

export const API_ACTIONS = {
  API_SUCCESS: 'API_SUCCESS',
  API_ERROR: 'API_ERROR'
};

export const DOWNLOAD_STATUS_ACTIONS = {
  DOWNLOAD_STATUS_UPDATE: 'DOWNLOAD_STATUS_UPDATE',
  SHOW_UPGRADE_SUBSCRIPTION: 'SHOW_UPGRADE_SUBSCRIPTION',
  HIDE_UPGRADE_SUBSCRIPTION: 'HIDE_UPGRADE_SUBSCRIPTION',
  SHOW_DOWNLOAD_LIMIT: 'SHOW_DOWNLOAD_LIMIT',
  HIDE_DOWNLOAD_LIMIT: 'HIDE_DOWNLOAD_LIMIT',
  SHOW_DOWNLOAD_LIMIT_4K: 'SHOW_DOWNLOAD_LIMIT_4K',
  HIDE_DOWNLOAD_LIMIT_4K: 'HIDE_DOWNLOAD_LIMIT_4K',
};

export const AUTH_ACTIONS = {
  AUTH_STATUS: 'AUTH_STATUS',
  AUTH_SIGN_IN: 'AUTH_SIGN_IN',
  AUTH_SHOW_LOGIN: 'AUTH_SHOW_LOGIN',
  AUTH_HIDE_LOGIN: 'AUTH_HIDE_LOGIN',
  AUTH_SHOW_SIGN_UP: 'AUTH_SHOW_SIGN_UP',
  AUTH_HIDE_SIGN_UP: 'AUTH_HIDE_SIGN_UP',
  AUTH_SIGN_OUT: 'AUTH_SIGN_OUT',
  AUTH_REGISTER: 'AUTH_REGISTER',
  AUTH_PASSWORDLESS_REGISTER: 'AUTH_PASSWORDLESS_REGISTER',
  AUTH_UPDATE_PROFILE: 'AUTH_UPDATE_PROFILE',
  AUTH_USER_INFO: 'AUTH_USER_INFO',
  AUTH_TEAM_INFO: 'AUTH_TEAM_INFO',
  AUTH_SIGN_IN_OK: 'AUTH_SIGN_IN_OK',
  AUTH_SIGN_IN_ERR: 'AUTH_SIGN_IN_ERR',
  AUTH_SIGN_OUT_OK: 'AUTH_SIGN_OUT_OK',
  AUTH_SIGN_OUT_ERR: 'AUTH_SIGN_OUT_ERR',
  AUTH_USER_INFO_OK: 'AUTH_USER_INFO_OK',
  AUTH_USER_INFO_ERR: 'AUTH_USER_INFO_ERR',
  AUTH_TEAM_INFO_OK: 'AUTH_TEAM_INFO_OK',
  AUTH_TEAM_INFO_ERR: 'AUTH_TEAM_INFO_ERR',
  AUTH_TEAM_INVITE_USER: 'AUTH_TEAM_INVITE_USER',
  AUTH_REGISTER_OK: 'AUTH_REGISTER_OK',
  AUTH_REGISTER_ERR: 'AUTH_REGISTER_ERR',
  AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',
  AUTH_RESET_ERR: 'AUTH_RESET_ERR',
  AUTH_SUBSCRIPTION_DETAILS: 'AUTH_SUBSCRIPTION_DETAILS',
  AUTH_SET_USER_NOT_LOGGED_IN: 'AUTH_SET_USER_NOT_LOGGED_IN',
  AUTH_DELETE_USER: 'AUTH_DELETE_USER',
  AUTH_ON_SUBSCRIBED: 'AUTH_ON_SUBSCRIBED',
  AUTH_SET_FREE_SUBSCRIBER_STATUS: 'AUTH_SET_FREE_SUBSCRIBER_STATUS',
  AUTH_ON_CANCELLED_SUBSCRIPTION: 'AUTH_ON_CANCELLED_SUBSCRIPTION',
  AUTH_ON_REACTIVATED_SUBSCRIPTION: 'AUTH_ON_REACTIVATED_SUBSCRIPTION',
  AUTH_SET_MIN_LICENSE_ID: 'AUTH_SET_MIN_LICENSE_ID',
};

export const AUTH_MW_ACTIONS = {
  AUTH_MW_STATUS: 'AUTH_MW_STATUS',
  AUTH_MW_SIGN_IN: 'AUTH_MW_SIGN_IN',
  AUTH_MW_SIGN_OUT: 'AUTH_MW_SIGN_OUT',
  AUTH_MW_USER_INFO: 'AUTH_MW_USER_INFO',
  AUTH_MW_TEAM_INFO: 'AUTH_MW_TEAM_INFO',
  AUTH_MW_SUBSCRIPTION_GET_DETAILS: 'AUTH_MW_SUBSCRIPTION_GET_DETAILS',
  AUTH_MW_TEAM_INVITE_USER: 'AUTH_MW_TEAM_INVITE_USER',
  AUTH_MW_REGISTER: 'AUTH_MW_REGISTER',
  AUTH_MW_RESET_PASSWORD: 'AUTH_MW_RESET_PASSWORD'
};

export const CREDIT_ACTIONS = {
  BUY_WITH_CREDITS: 'BUY_WITH_CREDITS',
  DECREMENT_AVAILABLE_CREDITS: 'DECREMENT_AVAILABLE_CREDITS',
  INCREMENT_AVAILABLE_CREDIS: 'INCREMENT_AVAILABLE_CREDIS',
  SET_AVAILABLE_CREDITS: 'SET_AVAILABLE_CREDITS',
};

export const PRODUCTS_ACTIONS = {
  SET_CATEGORY: 'SET_CATEGORY'
};

export const MESSAGES_ACTIONS = {
  SET_MESSAGES: 'SET_MESSAGES',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  ADD_MESSAGE: 'ADD_MESSAGE',
  CLEAR_MESSAGES: 'CLEAR_MESSAGES'
};

export const NOTIFICATIONS_ACTIONS = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'ADD_NOTIFICATION',
  ONE_TIME_NOTIFICATIONS: {
    SET_SHOWN: 'SET_SHOWN'
  }
};

export const CHECKOUT_ACTIONS = {
  GET_ACTIVE_ORDER: 'GET_ACTIVE_ORDER',
  RESET_PROGRESS: 'RESET_PROGRESS',
  SET_LOADING: 'SET_LOADING',
  SET_ERRORS: 'SET_ERRORS',
  GET_OPTIONS: 'GET_OPTIONS',
  CHECKOUT: 'CHECKOUT',
  CHECKOUT_CONFIRM_SUCCESS: 'CHECKOUT_CONFIRM_SUCCESS',
  SUBMIT_PAYMENT: 'SUBMIT_PAYMENT',
  PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
  PLACE_FREE_ORDER: 'PLACE_FREE_ORDER'
};

export const PP_ACTIONS = {
  GET_URL: 'GET_URL',
  SET_URL: 'SET_URL',
  CLEAR_URL: 'CLEAR_URL',
  SET_PARAMS: 'SET_PARAMS',
  CLEAR_PARAMS: 'CLEAR_PARAMS',
  SET_LOADING: 'SET_PP_LOADING'
};

export const STRIPE_ACTIONS = {
  SET_STRIPE_LOADED: 'SET_STRIPE_LOADED',
  SET_STRIPE_ERROR: 'SET_STRIPE_ERROR'
};

export const ANALYTICS_ACTIONS = {
  ADD_TO_CART: 'ANALYTICS_ADD_TO_CART',
  INIT_CHECKOUT: 'ANALYTICS_INIT_CHECKOUT',
  CONFIRMED_PAYMENT_INFO: 'ANALYTICS_CONFIRMED_PAYMENT_INFO',
  PURCHASE_COMPLETE: 'ANALYTICS_PURCHASE_COMPLETE'
};

export const PRODUCT_VARIANTS_PRICES_ACTIONS = {
  ADD_PRODUCT_VARIANTS_PRICES: 'ADD_PRODUCT_VARIANTS_PRICES',
  REMOVE_PRODUCT_VARIANTS_PRICES: 'REMOVE_PRODUCT_VARIANTS_PRICES',
  CLEAR_ALL_PRODUCTS_VARIANTS_PRICES: 'CLEAR_ALL_PRODUCTS_VARIANTS_PRICES'
};

export const WISHLISTS_ACTIONS = {
  SET_WISHLISTS: 'SET_WISHLISTS',
  ADD_WISHLIST: 'ADD_WISHLIST',
  REMOVE_WISHLIST: 'REMOVE_WISHLIST',
  UPDATE_WISHLIST: 'UPDATE_WISHLIST',
  ADD_TO_WISHLIST: 'ADD_TO_WISHLIST',
  REMOVE_FROM_WISHLIST: 'REMOVE_FROM_WISHLIST'
};

export const COLLECTIONS_CACHE_ACTIONS = {
  SET_COLLECTIONS_CACHE: 'SET_COLLECTIONS_CACHE',
  ADD_TO_COLLECTIONS_CACHE: 'ADD_TO_COLLECTIONS_CACHE'
};

export const OWNERSHIPS_CACHE_ACTIONS = {
  SET_OWNERSHIPS_CACHE: 'SET_OWNERSHIP_CACHE',
  ADD_TO_OWNERSHIPS_CACHE: 'ADD_TO_OWNERSHIP_CACHE',
  ADD_MULTIPLE_TO_OWNERSHIPS_CACHE: 'ADD_MULTIPLE_TO_OWNERSHIPS_CACHE',
  ADD_NOT_OWNED_PRODUCT_ID: 'ADD_NOT_OWNED_PRODUCT_ID'
};

export const LICENSE_TYPES_CACHE_ACTIONS = {
  CACHE_LICENSE_TYPES: 'CACHE_LICENSE_TYPES'
};

export const USER_VERIFICATION_ACTIONS = {
  SET_EMAIL_SENT: 'SET_EMAIL_SENT'
};

export const CATEGORIES_CACHE_ACTIONS = {
  SET_ENTRY: 'SET_ENTRY',
  ADD_PAGE: 'ADD_PAGE',
};

export const USER_INTERESTS_ANALYTICS_ACTIONS = {
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  ADD_RESULT_TO_BUFFER: 'ADD_RESULT_TO_BUFFER',
  EMPTY_BUFFER: 'EMPTY_BUFFER',
};

export const FILTERS_CACHE_ACTIONS = {
  SET_PFL_FILTERS: 'SET_PFL_FILTERS',
};

export const RATE_LIMITING_STATUS_ACTIONS = {
  VARIANT_DOWNLOADS_LIMIT_REACHED: 'VARIANT_DOWNLOADS_LIMIT_REACHED',
};

export const BUY_MORE_CREDITS_ACTIONS = {
  SHOW_MODAL: 'SHOW_BUY_MORE_CREDITS_MODAL',
  HIDE_MODAL: 'HIDE_BUY_MORE_CREDITS_MODAL',
}

export const CREDIT_PACKS_CACHE_ACTIONS = {
  SET_CREDIT_PACKS: 'SET_CREDIT_PACKS',
};

export const PLAN_VARIANTS_CACHE_ACTIONS = {
  SET_PLAN_VARIANTS: 'SET_PLAN_VARIANTS',
  CLEAR_PLAN_VARIANTS: 'CLEAR_PLAN_VARIANTS',
};

export const LICENSE_FLOW_ACTIONS = {
  SHOW_UPDATE_LICENSE_MODAL: 'SHOW_UPDATE_LICENSE_MODAL',
  HIDE_UPDATE_LICENSE_MODAL: 'HIDE_UPDATE_LICENSE_MODAL',
};

export const SUBSCRIPTION_UPGRADE_ACTIONS = {
  SHOW_UPGRADE_SUBSCRIPTION_MODAL: 'SHOW_UPGRADE_SUBSCRIPTION_MODAL',
  HIDE_UPGRADE_SUBSCRIPTION_MODAL: 'HIDE_UPGRADE_SUBSCRIPTION_MODAL',
};

export const EVENT_TRACKING_ACTIONS = {
  CURRENT_PRODUCT: {
    SET_PRODUCT: 'SET_PRODUCT_VISITED',
    CLEAR_PRODUCT: 'CLEAR_PRODUCT_VISITED',
  },
  HOMEPAGE: {
    SET_VISITING: 'SET_HOMEPAGE_VISITING',
    CLEAR_VISITING: 'CLEAR_HOMEPAGE_VISITING',
  },
  CONVERSION: {
    SET_SUBSCRIBED: 'SET_SUBSCRIBED',
    CLEAR_SUBSCRIBED: 'CLEAR_SUBSCRIBED',
  },
  PURCHASE: {
    SET_CREDIT_PACK: 'SET_CREDIT_PACK_PURCHASE',
    CLEAR_CREDIT_PACK: 'CLEAR_CREDIT_PACK_PURCHASE',
  },
  CHECKOUT: {
    SET_INITIATE_CHECKOUT: 'SET_INITIATE_CHECKOUT',
    CLEAR_INITIATE_CHECKOUT: 'CLEAR_INITIATE_CHECKOUT',
  },
};

export const ANONYMOUS_USER_ACTIONS = {
  SET_LICENSE_ID: 'SET_LICENSE_ID',
};

export const CART_ACTIONS = {
  OPEN_CART: 'OPEN_CART',
  CLOSE_CART: 'CLOSE_CART',
  UPDATE_ORDER: 'UPDATE_ORDER',
  SET_LOADING: 'SET_CART_LOADING',
  CLEAR_LOADING: 'CLEAR_CART_LOADING',
};
