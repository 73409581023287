import { CART_ACTIONS } from '../../constants/actions';
import { CartState, CartOrderState } from '../../types/state/reducers/cartReducersTypes';
import { newState } from '../../helpers/state/reducers/new-state';

export const initialCartOrderState: CartOrderState = {
  licenseId: 1,
  products: {},
  discount: {
    code: null,
    percentage: null,
    value: null,
    error: false
  },
  subtotal: null,
  total: null
};

const initialCartState: CartState = {
  initialized: false,
  loading: false,
  active: false,
  order: initialCartOrderState,
  free: false
} as const;

export const cartReducer = (
  state: CartState = initialCartState,
  action: {
    type: string;
    payload?: CartOrderState;
  }
) => {
  switch (action.type) {
    case CART_ACTIONS.OPEN_CART:
      return newState<CartState>(state, { active: true });
    case CART_ACTIONS.CLOSE_CART:
      return newState<CartState>(state, { active: false });
    case CART_ACTIONS.SET_LOADING:
      return newState<CartState>(state, { loading: true });
    case CART_ACTIONS.CLEAR_LOADING:
      return newState<CartState>(state, { loading: false });
    case CART_ACTIONS.UPDATE_ORDER:
      const order: CartOrderState = action.payload;
      const cart = newState<CartState>(state, {
        order,
        initialized: true,
        free: Boolean(order.total <= 0),
        loading: false
      });
      return cart;
    default:
      return state;
  }
};
