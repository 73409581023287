import thunk from 'redux-thunk';
import { applyMiddleware, StoreEnhancer } from 'redux';
import { initApiClient } from './api';
import { authMiddleware as auth } from './auth';
import { analyticsMiddleware as analytics } from './analytics';
import { API_CONFIG_STORE } from './../../constants/api';

const apiClient = initApiClient(API_CONFIG_STORE);

export const middleware: StoreEnhancer = applyMiddleware(thunk, auth, analytics, apiClient);
