import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import { ANALYTICS_ACTIONS } from './../../constants/actions';
import { addToCart, initCheckout, addPaymentInfo, purchased } from './../../shared/analytics';
import { ApplicationState } from './../../types/state/storeTypes';
import { CartOrderState, CartProductType } from './../../types/state/reducers/cartReducersTypes';
import { OrderResponse } from './../../types/api/OrderTypes';

const { ADD_TO_CART, INIT_CHECKOUT, CONFIRMED_PAYMENT_INFO, PURCHASE_COMPLETE } = ANALYTICS_ACTIONS;

const addToCartQueue = {};

const enqueueCartAddition = (id: number) => {
  addToCartQueue[id] = true;
};

const getCartOrder = (store: MiddlewareAPI): CartOrderState => {
  const state: ApplicationState = store.getState();
  return state.cart.order;
};

const cartListener = (cartOrder: CartOrderState) => {
  Object.values(cartOrder.products).forEach((cartProduct: CartProductType) => {
    if (!addToCartQueue[cartProduct.id]) return;

    addToCart(cartOrder, cartProduct);
    delete addToCartQueue[cartProduct.id];
  });
};

export const analyticsMiddleware: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (
  action: AnyAction
): void => {
  next(action);
  const trackActions = {
    [ADD_TO_CART]: () => enqueueCartAddition(action.id as number),
    [INIT_CHECKOUT]: () => initCheckout(getCartOrder(store)),
    [CONFIRMED_PAYMENT_INFO]: () => addPaymentInfo(getCartOrder(store)),
    [PURCHASE_COMPLETE]: () => purchased(action.order as OrderResponse),
  };

  if (trackActions[action.type]) trackActions[action.type]();
};
